import React, { useEffect } from "react";
import ProfilageIntro from "./profilage/ProfilageIntro";
import TableauAutoEvaluation from "./profilage/TableauAutoEvaluation";
import CouplesBoutons from "./profilage/CouplesBoutons";
import Sequence from "../form/Sequence";
import translations from "../../gen/translations.json";

export default function Profilage(props) {
  useEffect(() => {
    props.onProgress(null);
  }, []);
  const values = props.values.profilage || {};
  const t = translations.breadcrumb.profilage;
  return (
    <Sequence {...props}>
      <ProfilageIntro
        path="/"
        title={t.step1}
        isComplete={values.isIntroComplete}
      />
      <TableauAutoEvaluation
        path="/tableau-auto-evaluation"
        title={t.step2}
        readOnly={props.readOnly}
        result={false}
        isComplete={values.isSelfEvaluationGridComplete}
      />
      <CouplesBoutons
        path="/couples-boutons"
        title={t.step3}
        isComplete={values.normalized}
        readOnly={props.readOnly}
      />
      <TableauAutoEvaluation
        path="/resultats"
        title={t.step4}
        readOnly={props.readOnly}
        result={true}
        isLocked={!values.normalized}
        isComplete={values.isProfilageResultsComplete}
      />
    </Sequence>
  );
}
