import { Button, Nav, NavItem } from "reactstrap";
import { Link } from "@reach/router";
import React from "react";
import translations from "../../gen/translations.json";

export default function CourseFooter({ nextPath, prevPath, disabled }) {
  const t = translations.global.footer;

  return (
    <Nav className="course-footer">
      <NavItem>
        {prevPath && (
          <Link to={prevPath} className="nav-link">
            <Button color="secondary">{t.previous}</Button>
          </Link>
        )}
      </NavItem>
      <NavItem>
        {nextPath && (
          <Link to={nextPath} className="nav-link">
            <Button color="primary" disabled={disabled}>
              {t.next}
            </Button>
          </Link>
        )}
      </NavItem>
    </Nav>
  );
}
