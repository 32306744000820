class AccessCode {
  startsAt = null;
  endsAt = null;
  id = null;
  uuid = null;
  courseType = null;

  saveLocally() {
    window.localStorage.setItem(`access_code_${this.uuid}`, this.getJson());
  }

  getJson() {
    return JSON.stringify({
      startsAt: this.startsAt,
      endsAt: this.endsAt,
      id: this.id,
      uuid: this.uuid,
      courseType: this.courseType,
    });
  }
}

AccessCode.createFromApiData = function (data) {
  const code = new AccessCode();
  const dateParts = data.validBetween.split(",");
  code.startsAt = new Date(dateParts[0]);
  code.endsAt = new Date(
    new Date(dateParts[1]).getTime() + 24 * 60 * 60 * 1000
  );
  code.courseType = data.course;
  code.id = parseInt(data.id);
  code.uuid = data.uuid;
  return code;
};

AccessCode.openLocal = function (uuid) {
  const json = window.localStorage.getItem(`access_code_${uuid}`);
  if (json) {
    return AccessCode.createFromJson(json);
  }
  throw new Error(`Could not revive access code ${uuid}`);
};

AccessCode.createFromJson = function (data) {
  const code = new AccessCode();
  code.startsAt = new Date(data.startsAt);
  code.endsAt = new Date(data.endsAt);
  code.courseType = data.courseType;
  code.id = data.id;
  code.uuid = data.uuid;
};

export default AccessCode;
