import React, { useEffect } from "react";
import GridForm from "../../form/GridForm";
import CourseFooter from "../../form/CourseFooter";
import { navigate } from "@reach/router";
import { Row, Col } from "reactstrap";

export default function GrillePage({
  id,
  grid,
  onChange,
  onProgress,
  indexInSequence,
  childrenInSequence,
  values,
  readOnly,
  ...props
}) {
  const gridFormValues =
    values.appetence?.grilles && values.appetence.grilles[id]
      ? values.appetence.grilles[id]
      : {
          approved: [],
          rejected: [],
        };
  useEffect(() => {
    onProgress({ current: indexInSequence, total: childrenInSequence });
    if (indexInSequence === childrenInSequence - 1) {
      onChange((draft) => {
        if (!draft.appetence) {
          draft.appetence = { grilles: {} };
        }
        draft.appetence.isGrillesComplete = true;
      });
    }
  }, []);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        navigate(props.nextPath);
      }}
    >
      <Row>
        <Col
          sm={{ size: 12, offset: 0 }}
          md={{ size: 10, offset: 1 }}
          lg={{ size: 6, offset: 3 }}
        >
          <GridForm
            questions={grid}
            values={gridFormValues}
            readOnly={readOnly}
            onChange={({ approved, rejected }) => {
              onChange((draft) => {
                if (!draft.appetence || !draft.appetence.grilles) {
                  draft.appetence.grilles = {};
                  draft.appetence.grilles[id] = {};
                }

                draft.appetence.grilles[id] = { approved, rejected };
              });
            }}
          />
        </Col>
      </Row>
      <CourseFooter
        {...props}
        disabled={
          !(
            gridFormValues.approved.length === 3 &&
            gridFormValues.rejected.length === 1
          )
        }
      />
    </form>
  );
}
