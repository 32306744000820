import React from "react";
import TextAreaForm from "../../form/TextAreaForm";
import translations from "../../../gen/translations.json";

export default function MonParcours(props) {
  return (
    <TextAreaForm
      {...props}
      translations={translations.perspective.parcours}
      completionProperty="perspective.isMonParcoursComplete"
      property="perspective.parcours"
      groups={[
        {
          name: "parcours",
          fields: [
            "diplomes",
            "connaissances_linguistiques",
            "capacites_informatiques",
            "hobbies",
          ],
        },
        {
          name: "disciplines",
          fields: ["disciplines_preferees", "disciplines_moins_appreciees"],
        },
        {
          name: "professionnel",
          fields: ["stages", "competences"],
        },
        { name: "conclusions", fields: ["conclusion"] },
      ]}
    />
  );
}
