import React, { useEffect } from "react";
import { Row, Col, Container, Card, CardBody, Button } from "reactstrap";
import { navigate } from "@reach/router";

import CourseFooter from "../../form/CourseFooter";
import TextArea from "../../form/TextArea";
import Helpers from "../../../classes/Helpers";

import translations from "../../../gen/translations.json";

import AssetMahpiLogo from "../../../assets/logo-mahpi.png";
import AssetProfilageLogo from "../../../assets/logo-profilage-blanc.png";
import Spacer from "../../layout/Spacer";

export default function ProfilageSynthese({ ...props }) {
  const { nextPath, onChange, onProgress, values, readOnly } = props;

  const t = translations.profilage.synthese;

  const profilageValues = values.profilage?.synthese ?? {};
  const profilageNormalizedSkillsValues = values.profilage?.normalized ?? {};
  const profilageScores = { assets: {}, mahpi: [], soft: {} };

  useEffect(() => {
    onProgress(null);
  }, []);

  const renderSkillLevelList = (skills) => {
    return skills.map((shortName) => {
      const skill = Helpers.getSkillByShortName(shortName);
      return (
        skill && (
          <li key={shortName}>
            <strong>{skill.name}</strong>
            <div>{skill.description}</div>
          </li>
        )
      );
    });
  };

  /**
   * Normalize asset level
   */
  Object.entries(profilageNormalizedSkillsValues).forEach(([key, value]) => {
    const level = Helpers.getAssetLevel(value);
    if (profilageScores.assets[level] === undefined) {
      profilageScores.assets[level] = [];
    }
    profilageScores.assets[level].push(key);
  });

  const isComplete = () => {
    return !!values.profilage?.synthese;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isComplete()) {
      navigate(nextPath);
    }
  };

  const handleChange = (property) => (event) => {
    const { value } = event.target;
    onChange((draft) => {
      if (!draft.profilage.synthese) {
        draft.profilage.synthese = {};
      }
      draft.profilage.synthese[property] = value;
      draft.profilage.isSyntheseComplete =
        Object.values(draft.profilage.synthese).length >= 8;
    });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <div>
          <Spacer size={3} />
          <h2>{t.title}</h2>
          <h3>{t.assets.title}</h3>
          <Row>
            <Col sm={2}>
              <img src={AssetProfilageLogo} alt="Logo Profilage" width="150" />
            </Col>
            <Col sm={10}>
              <h4>{t.assets.high_title}</h4>
              <div>
                <u>{t.assets.high_label}</u>
                <ul>
                  {renderSkillLevelList(profilageScores.assets?.high || [])}
                </ul>
              </div>

              <TextArea
                values={profilageValues}
                onChange={handleChange}
                name="assets_evidence_high"
                label={t.field_label}
                disabled={readOnly}
              />

              <h4>{t.assets.low_title}</h4>
              <div>
                <u>{t.assets.low_label}</u>
                <ul>
                  {renderSkillLevelList(profilageScores.assets?.low || [])}
                </ul>
              </div>

              <TextArea
                values={profilageValues}
                onChange={handleChange}
                name="assets_evidence_low"
                label={t.field_label}
                disabled={readOnly}
              />
            </Col>
          </Row>

          <h2>{t.mahpi.title}</h2>

          <Row>
            <Col sm={2}>
              <img src={AssetMahpiLogo} alt="Logo MAHPI" width="150" />
            </Col>
            <Col sm={10}>
              {Helpers.getMahpiScore(profilageNormalizedSkillsValues)
                .slice(0, 2)
                .map((value, key) => (
                  <div key={key}>
                    <div>
                      <strong>
                        <u>
                          {t.mahpi.trend_title}{" "}
                          <span className="text-uppercase">{value.name}</span>
                        </u>
                      </strong>
                    </div>
                    <ul>
                      {value.skills.map((value, index) => (
                        <li key={index}>{value}</li>
                      ))}
                    </ul>
                    <TextArea
                      values={profilageValues}
                      onChange={handleChange}
                      name={`mahpi_${key}`}
                      label={t.field_label}
                      disabled={readOnly}
                    />
                  </div>
                ))}
            </Col>
          </Row>

          <h2>{t.soft_skills.title}</h2>

          <Row>
            <Col sm={{ size: 10, offset: 2 }}>
              {[
                {
                  title: t.soft_skills.high_title,
                  level: "high",
                  field_label: t.field_label,
                },
                {
                  title: t.soft_skills.low_title,
                  level: "low",
                  field_label: t.soft_skills.low_field_label,
                },
              ].map((item, index) => (
                <div key={index}>
                  <h3>{item.title}</h3>
                  {Array.from(
                    Helpers.getSoftSkillsScores(
                      profilageNormalizedSkillsValues
                    ).grouped.get(item.level),
                    ([key, value]) => (
                      <div key={key}>
                        <strong>{key}</strong>
                        <ul>
                          {value.map((value, index) => (
                            <li key={index}>
                              {value.name} : {value.average}
                            </li>
                          ))}
                        </ul>
                        <TextArea
                          values={profilageValues}
                          onChange={handleChange}
                          name={`soft_skill_${item.level}_${key}`}
                          label={item.field_label}
                          disabled={readOnly}
                        />
                      </div>
                    )
                  )}
                </div>
              ))}
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Button>{translations.perspective.parcours.save_btn}</Button>
            </CardBody>
          </Card>
          <CourseFooter {...props} />
        </div>
      </form>
    </Container>
  );
}
