import Sequence from "../form/Sequence";
import React from "react";
import AppetenceIntro from "./appetence/AppetenceIntro";
import Grilles from "./appetence/Grilles";
import AppetenceResultats from "./appetence/AppetencesResultats";
import translations from "../../gen/translations.json";

export default function Appetence({ ...props }) {
  const t = translations.breadcrumb.appetence;
  const values = props.values.appetence || {};

  return (
    <Sequence {...props}>
      <AppetenceIntro
        path="/"
        title={t.step1}
        isComplete={values.isIntroComplete}
      />
      <Grilles
        path="/grilles/*"
        title={t.step2}
        isComplete={values.isGrillesComplete}
        readOnly={props.readOnly}
      />
      <AppetenceResultats
        path="/resultats"
        title={t.step3}
        isLocked={!values.isGrillesComplete}
        isComplete={values.isResultsComplete}
      />
    </Sequence>
  );
}
