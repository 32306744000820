import CourseFooter from "../../form/CourseFooter";
import React, { useEffect } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";

import translations from "../../../gen/translations.json";
import AssetAppetenceLogo from "../../../assets/logo-appetence.png";
import Helpers from "../../../classes/Helpers";
import Spacer from "../../layout/Spacer";

export default function AppetenceIntro({ ...props }) {
  const t = translations.appetence.intro;
  useEffect(() => {
    props.onChange((draft) => {
      if (!draft.appetence) {
        draft.appetence = {};
      }
      draft.appetence.isIntroComplete = true;
    });
  }, []);
  return (
    <Container>
      <Spacer size={3} />
      <h2>{t.title}</h2>
      <Card>
        <CardBody>
          <Row>
            <Col sm={4} md={4} lg={3}>
              <img src={AssetAppetenceLogo} alt="Test appétence" width="100%" />
            </Col>
            <Col>
              <p>{t.text1}</p>
              {Helpers.textToList(t.list1, "ul")}
              <p>{t.text2}</p>
              {Helpers.textToList(t.list2, "ul")}
              {Helpers.textToList(t.text3, "div")}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <CourseFooter {...props} />
    </Container>
  );
}
