import React from "react";
import {
  UncontrolledAlert,
  NavbarBrand,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Button,
  Progress,
} from "reactstrap";
import { FilePersonFill } from "react-bootstrap-icons";
import { Router, navigate, Link } from "@reach/router";

import translations from "./gen/translations.json";

import SignupForm from "./components/form/SignupForm";
import LostPasswordForm from "./components/form/LostPasswordForm";
import ResetPasswordForm from "./components/form/ResetPasswordForm";

import { Course } from "./components/pages/Course";
import { Home } from "./components/pages/Home";
import Api from "./Api";

import AssetGalateeLogo from "./assets/logo.png";
import AccountForm from "./components/form/AccountForm";
import * as Sentry from "@sentry/react";

const api = new Api();

class App extends React.Component {
  state = {
    isDisconnected: false,
    isSavingModifications: false,
    breadcrumb: null,
    progress: null,
    footer: null,
    authStatus: "",
    fetchStatus: "",
    saveStatus: "",
    onLineStatus: "",
  };

  genderTitles = {
    n: translations.global.header.gender_n,
    f: translations.global.header.gender_f,
    m: translations.global.header.gender_m,
  };

  componentDidMount() {
    const pathname = window.location.pathname || "/";
    const anonymousPaths = [
      "/signup",
      "/lost-password",
      "/lost-password-reset",
    ];
    window.api = api;

    api.on("fetch", (fetchStatus) => this.setState({ fetchStatus }));
    api.on("save", (saveStatus) => this.setState({ saveStatus }));
    api.on("auth", (authStatus) => this.setState({ authStatus }));
    api.on("onLine", (onLineStatus) => this.setState({ onLineStatus }));
    if (anonymousPaths.indexOf(pathname) < 0) {
      api
        .silentLogin()
        .then((user) => {
          this.setState({ user });
          navigate(user.uid ? pathname : "/signup");
        })
        .catch(() => navigate("/signup"));
    }
  }

  render() {
    return (
      <div className="app">
        <Navbar color="light" bg="light" light expand="md" className="main">
          <NavbarBrand href="/">
            <img src={AssetGalateeLogo} alt="Galatée Conseil" height={100} />
          </NavbarBrand>
          {this.state.user && (
            <Nav vertical pills className="ml-auto">
              <NavItem>
                <FilePersonFill size={32} />
                <Link to="/account">
                  {this.genderTitles[this.state.user.gender]}{" "}
                  {this.state.user.lastname}
                </Link>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Button onClick={api.logout}>
                    {translations.global.header.logout}
                  </Button>
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </Navbar>

        {/* Le breadcrumb est généré par le composant Sequence */}
        {this.state.breadcrumb}

        {this.state.progress ? (
          <Progress
            className="main"
            animated={this.state.progress.current < this.state.progress.total}
            value={this.state.progress.current}
            max={this.state.progress.total}
          />
        ) : (
          <Progress className="main" value={1} max={1} />
        )}
        <>
          <div>
            {this.state && this.state.errorMessage && (
              <UncontrolledAlert
                color="danger"
                className="fixed-top m-2 w-75 mx-auto text-center offset-4"
              >
                {this.state.errorMessage}
              </UncontrolledAlert>
            )}
          </div>
          <div>
            {this.state && this.state.successMessage && (
              <UncontrolledAlert
                color="success"
                className="fixed-top m-2 w-75 mx-auto text-center offset-4"
              >
                {this.state.successMessage}
              </UncontrolledAlert>
            )}
          </div>
          <Router>
            <Home path="/" api={api} user={this.state.user} />
            <Course
              path="/courses/:courseId/*"
              api={api}
              onBreadcrumb={(breadcrumb) => this.setState({ breadcrumb })}
              onProgress={(progress) => this.setState({ progress })}
              onFooter={(footer) => this.setState({ footer })}
              saveStatus={this.state.saveStatus}
            />
            <SignupForm
              path="/signup"
              defaultValues={{}}
              onLoginSubmit={(values) => {
                api.login(values.email, values.pass).then((user) => {
                  if (user && user.uid) {
                    this.setState({ user, errorMessage: null });
                    navigate("/");
                  } else {
                    this.setState({
                      errorMessage:
                        translations.global.errors.invalid_mail_or_pass,
                    });
                  }
                });
              }}
              onSignupSubmit={(values) => {
                api
                  .register(values)
                  .then((user) => {
                    this.setState({ user, errorMessage: null });
                    navigate("/");
                  })
                  .catch((error) => {
                    if (!error.response) {
                      Sentry.captureException(error);
                      console.error(error);
                      return this.setState({
                        errorMessage: "no response in error",
                      });
                    }
                    if (
                      error.response.status === 422 &&
                      error.response.statusText === "Unprocessable Entity"
                    ) {
                      this.setState({
                        errorMessage:
                          translations.global.errors.email_already_in_use,
                      });
                    }
                  });
              }}
              api={api}
            />
            <AccountForm
              path="/account"
              defaultValues={this.state.user}
              onSubmit={(values) => {
                api.updateUser(values).then((response) => {
                  if (response.status === 200) {
                    const user = response.data;
                    this.setState(
                      {
                        user,
                        errorMessage: null,
                        successMessage: translations.account.confirm_message,
                      },
                      () => {
                        api.refreshToken().then(() => {
                          navigate("/");
                        });
                      }
                    );
                  } else {
                    this.setState({
                      errorMessage: response.data.message,
                    });
                  }
                });
              }}
              api={api}
            />
            <LostPasswordForm
              path="/lost-password"
              defaultValues={{}}
              onSubmit={(values) => {
                api.lostPassword(values.email).then((response) => {
                  this.setState({ errorMessage: "" });
                  if (response.status === 200) {
                    this.setState({
                      successMessage: translations.global.status.new_pass_sent,
                    });
                    navigate("/lost-password-reset");
                  }
                  if (response.status === 400 && response.data.message) {
                    this.setState({
                      errorMessage: translations.global.errors.invalid_mail,
                    });
                  }
                });
              }}
              api={api}
            />
            <ResetPasswordForm
              path="/lost-password-reset"
              defaultValues={{}}
              onSubmit={(values) => {
                api
                  .resetPassword(
                    values.email,
                    values.temp_pass,
                    values.new_pass
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      this.setState({
                        successMessage:
                          translations.global.status.login_new_pass,
                      });
                      navigate("/signup");
                    }
                  });
              }}
              api={api}
            />
          </Router>
        </>
      </div>
    );
  }
}

export default App;
