import React, { useEffect } from "react";
import CourseFooter from "../../form/CourseFooter";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
import translations from "../../../gen/translations.json";
import AssetPerspectiveLogo from "../../../assets/logo-perspective.png";
import Spacer from "../../layout/Spacer";
import Helpers from "../../../classes/Helpers";

export default function PerspectiveIntro({ ...props }) {
  const t = translations.perspective.intro;
  useEffect(
    () =>
      props.onChange((draft) => {
        if (!draft.perspective) {
          draft.perspective = {};
        }
        draft.perspective.isIntroComplete = true;
      }),
    []
  );
  return (
    <Container>
      <Spacer size={3} />
      <h2>{t.title}</h2>
      <Spacer size={3} />
      <Card>
        <CardHeader>
          <CardTitle>{Helpers.textToList(t.card_title, "div")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={4} md={4} lg={3}>
              <img
                src={AssetPerspectiveLogo}
                alt="Test perspective"
                width="100%"
              />
            </Col>
            <Col>
              <ul>
                {Object.values(t.steps).map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <CourseFooter {...props} />
    </Container>
  );
}
