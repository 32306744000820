import React from "react";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import Spacer from "../layout/Spacer";

import translations from "../../gen/translations.json";

/**
 * Returns the default values structure of the component.
 *
 * @returns {Object}
 */
function GetDefaultValues() {
  return {
    uid: 0,
    gender: "n",
    lastname: "",
    firstname: "",
    mail: "",
    pass: "",
    pass_confirm: "",
  };
}

/**
 * <SignupForm> component definition.
 */
export default class AccountForm extends React.Component {
  state = {
    values: { ...GetDefaultValues(), ...this.props.defaultValues },
    passwordMatch: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultValues?.uid !== this.props.defaultValues?.uid) {
      this.setState({
        values: { ...GetDefaultValues(), ...this.props.defaultValues },
      });
    }

    const passwordMatch = (this.state.values.pass === this.state.values.pass_confirm);

    if (passwordMatch !== prevState.passwordMatch) {
      this.setState({passwordMatch});
    }
  }

  render() {
    // Init translation system.
    const t = translations.account;
    const values = this.state.values;
    const passwordMatch = this.state.passwordMatch;

    /**
     * onChange input callback.
     *
     * @param {Object} event
     */
    const onChange = (event) => {
      this.setState({
        values: { ...values, [event.target.name]: event.target.value },
      });
    };

    return (
      <Container>
        <Spacer size={5} />
        <Card>
          <CardHeader>
            <h3 className="pt-3 pb-1">{t.title}</h3>
          </CardHeader>
          <CardBody className="p-4">
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                // TODO : Find a better way.
                if (!passwordMatch) {
                  return;
                }
                this.props.onSubmit(values);
              }}
            >
              <FormGroup row>
                <Label for="genderSelect" sm={4}>
                  {t.gender}*
                </Label>
                <Col sm={8}>
                  {["n", "f", "m"].map((gender, index) => (
                    <FormGroup check inline key={index}>
                      <Label check>
                        <Input
                          type="radio"
                          name="gender"
                          onChange={onChange}
                          value={gender}
                          checked={values.gender === gender}
                        />{" "}
                        {t[`gender_${gender}`]}
                      </Label>
                    </FormGroup>
                  ))}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="lastnameText" sm={4}>
                  {t.lastname}*
                </Label>
                <Col sm={8}>
                  <Input
                    type="text"
                    name="lastname"
                    id="lastnameText"
                    value={values.lastname}
                    onChange={onChange}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="firstnameText" sm={4}>
                  {t.firstname}*
                </Label>
                <Col sm={8}>
                  <Input
                    type="text"
                    name="firstname"
                    id="firstnameText"
                    value={values.firstname}
                    onChange={onChange}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="emailText" sm={4}>
                  {t.email}*
                </Label>
                <Col sm={8}>
                  <Input
                    type="email"
                    name="mail"
                    id="emailText"
                    value={values.mail}
                    onChange={onChange}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="passwordText" sm={4}>
                  {t.password}
                </Label>
                <Col sm={8}>
                  <Input
                    type="password"
                    name="pass"
                    id="passwordText"
                    onChange={onChange}
                    value={values.pass}
                    invalid={!passwordMatch}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="passwordConfirmText" sm={4}>
                  {t.confirm_password}
                </Label>
                <Col sm={8}>
                  <Input
                    type="password"
                    name="pass_confirm"
                    id="passwordConfirmText"
                    onChange={onChange}
                    value={values.pass_confirm}
                    invalid={!passwordMatch}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={{ size: 8, offset: 4 }}>
                  <Button color="primary" disabled={!passwordMatch}>{t.save}</Button>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
