import React, { useEffect, useState } from "react";
import { Tooltip, Card, CardBody, CardText, CardHeader } from "reactstrap";
import { CircleFill, PatchQuestion } from "react-bootstrap-icons";

function SelfEvaluationLine({
  item,
  value,
  resultValue,
  onChange,
  readOnly,
  result,
  colors,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const tmp = [];
  for (let i = 1; i <= 11; i++) {
    tmp.push(i);
  }

  const renderElt = function (number) {
    if (value === number && resultValue === number) {
      return (
        <>
          <CircleFill color={colors.selfEvaluationValue.disc} />
          <CircleFill color={colors.resultValue.disc} />
        </>
      );
    }
    if (value === number) {
      return <CircleFill color={colors.selfEvaluationValue.disc} />;
    }
    if (resultValue === number) {
      return <CircleFill color={colors.resultValue.disc} />;
    }
    return <span />;
  };

  const getBgColor = (number) => {
    let bgColor = "";
    if (!result) {
      bgColor = number === value ? colors.selfEvaluationValue.background : "";
      return bgColor;
    }
    if (number === resultValue) {
      bgColor = colors.resultValue.background;
      if (number === value) {
        bgColor = ""; //colors.match.background;
      }
    } else if (number === value) {
      bgColor = colors.selfEvaluationValue.background;
    }
    /*
    Coloration des cases entre deux évaluations
    if (number > resultValue && number < value) {
      bgColor = colors.between.background;
    }
    if (number > value && number < resultValue) {
      bgColor = colors.between.background;
    }*/

    return bgColor;
  };

  return (
    <Card className="mb-4">
      <CardBody>
        <CardText>
          <strong>
            {item.name}
            <span className="px-3" id={"tooltip" + item.shortName}>
              <PatchQuestion />
            </span>
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target={"tooltip" + item.shortName}
              toggle={toggle}
            >
              {item.description}
            </Tooltip>
          </strong>
        </CardText>
        <div className="notation-scale" data-readonly={result || readOnly}>
          <div className="begin">{item.begin}</div>
          {tmp.map((number) => (
            <div
              className="note"
              key={item.shortName + number}
              style={{ backgroundColor: getBgColor(number) }}
              onClick={() => {
                // Click on the table cell
                if (!result && !readOnly) {
                  onChange(item.shortName, number);
                }
              }}
            >
              <div>{number}</div>
              {result ? (
                renderElt(number)
              ) : (
                <input
                  disabled={readOnly}
                  checked={value === number}
                  className="m-0"
                  type="radio"
                  name={item.name}
                  value={number}
                  onChange={() => {
                    onChange(item.shortName, number);
                  }}
                />
              )}
            </div>
          ))}
          <div className="end">{item.end}</div>
        </div>
      </CardBody>
    </Card>
  );
}

export default function SelfEvaluationGrid({
  items,
  values,
  onChange,
  readOnly,
  result,
  colors,
}) {
  const autoevalValues = values.profilage?.autoeval || {};
  const resultValues = values.profilage?.normalized || {};
  const itemsLength = items.length;
  useEffect(() => {
    onChange((draft) => {
      draft.profilage = draft.profilage || {};
      draft.profilage.isSelfEvaluationGridComplete =
        itemsLength === Object.values(autoevalValues).length;
    });
  }, [autoevalValues]);

  const groupedItems = items.reduce((newItems, item) => {
    (newItems[item.type] = newItems[item.type] || []).push(item);
    return newItems;
  }, {});

  return (
    <div>
      {Object.keys(groupedItems).map((item, index) => (
        <Card key={index} className={"header-color-" + index + " mb-4"}>
          <CardHeader className={"background-color-" + index}>
            <span className="text-uppercase font-weight-bold">{item}</span>
          </CardHeader>
          <CardBody>
            {groupedItems[item].map((item, index) => (
              <SelfEvaluationLine
                key={index}
                item={item}
                value={autoevalValues[item.shortName]}
                resultValue={resultValues[item.shortName]}
                colors={colors}
                onChange={(name, value) => {
                  onChange((draft) => {
                    if (!draft.profilage) {
                      draft.profilage = { autoeval: {} };
                    }
                    if (!draft.profilage.autoeval) {
                      draft.profilage.autoeval = {};
                    }
                    draft.profilage.autoeval[name] = value;
                  });
                }}
                readOnly={readOnly}
                result={result}
              />
            ))}
          </CardBody>
        </Card>
      ))}
    </div>
  );
}
