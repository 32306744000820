import React, { useEffect } from "react";
import CourseFooter from "../../form/CourseFooter";
import translations from "../../../gen/translations.json";
import { Card, CardBody, Container, Col, Row } from "reactstrap";
import Spacer from "../../layout/Spacer";
import Helpers from "../../../classes/Helpers";
import AssetProfilageLogo from "../../../assets/logo-profilage-blanc.png";

export default function ProfilageIntro({ ...props }) {
  const t = translations.profilage;
  useEffect(
    () =>
      props.onChange((draft) => {
        if (!draft.profilage) {
          draft.profilage = {};
        }
        draft.profilage.isIntroComplete = true;
      }),
    []
  );

  return (
    <Container>
      <Spacer size={3} />
      <h2>{t.title}</h2>
      <Card>
        <CardBody>
          <Row>
            <Col sm={4} md={4} lg={3}>
              <img src={AssetProfilageLogo} alt="Test profilage" width="100%" />
            </Col>
            <Col>{Helpers.textToList(t.intro.text, "p")}</Col>
          </Row>
        </CardBody>
      </Card>

      <CourseFooter {...props} />
    </Container>
  );
}
