import CourseFooter from "../../form/CourseFooter";
import React from "react";
import profilage from "../../../gen/profilage.json";
import Helpers from "../../../classes/Helpers";
import {
  Container,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
} from "reactstrap";
import translations from "../../../gen/translations.json";
import Spacer from "../../layout/Spacer";
import WordPairForm from "../../form/WordPairForm";

export default function CouplesBoutons({
  onChange,
  values,
  onProgress,
  ...props
}) {
  const t = translations.profilage.couplesmots;
  return (
    <Container>
      <Spacer size={3} />
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>{t.title}</CardTitle>
            </CardHeader>
            <CardBody>
              {Helpers.textToList(t.intro.text, "p")}
              {Helpers.textToList(t.intro.list, "ul")}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Spacer size={3} />
      <Row>
        <Col>
          <WordPairForm
            path="/pairs"
            pairs={profilage.couple_mots}
            values={values.profilage?.selectedSkills || {}}
            readOnly={props.readOnly}
            nextPath={props.nextPath}
            onChange={(selectedSkills) => {
              onChange((draft) => {
                if (!draft.profilage) {
                  draft.profilage = {};
                }
                const selectedSkillsCount = Object.values(selectedSkills)
                  .length;
                const skillsCount = profilage.couple_mots.length;
                if (selectedSkillsCount >= skillsCount) {
                  draft.profilage.normalized = Helpers.normalizeWordPairScores(
                    values.profilage.selectedSkills
                  );
                } else {
                  draft.profilage.normalized = null;
                }

                draft.profilage.selectedSkills = selectedSkills;
              });
            }}
            onProgress={onProgress}
          />
        </Col>
      </Row>
      <CourseFooter {...props} disabled={!values.profilage?.normalized} />
    </Container>
  );
}
