import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

export default function TextArea({ name, label, values, onChange, disabled }) {
  return (
    <FormGroup className="formgroup-textarea p-3">
      <Label>{label}</Label>
      <Input
        required
        type="textarea"
        value={values[name] || ""}
        onChange={onChange(name)}
        disabled={disabled}
      />
    </FormGroup>
  );
}
