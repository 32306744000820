import React, { useEffect } from "react";
import CourseFooter from "../../form/CourseFooter";
import { Card, Container, CardBody, Row, Col, CardHeader } from "reactstrap";
import Spacer from "../../layout/Spacer";
import Helpers from "../../../classes/Helpers";

import translations from "../../../gen/translations.json";
import appetence from "../../../gen/appetence.json";

import AssetAppetenceLogo from "../../../assets/logo-appetence.png";

function getScores(values) {
  let riasecScores = [];
  let sheinScores = [];

  if (values) {
    Object.values(values).forEach((grille, index) => {
      const scores = index < 8 ? riasecScores : sheinScores;
      grille.approved.forEach((item) => {
        let scoreIndex = scores.findIndex((element) => element.id === item);
        if (scoreIndex === -1) {
          scoreIndex =
            scores.push({
              id: item,
              value: 0,
            }) - 1;
        }

        scores[scoreIndex].value++;
      });
      grille.rejected.forEach((item) => {
        let scoreIndex = scores.findIndex((element) => element.id === item);
        if (scoreIndex === -1) {
          scoreIndex =
            scores.push({
              id: item,
              value: 0,
            }) - 1;
        }
        scores[scoreIndex].value--;
      });
    });
  }

  riasecScores.sort(function (a, b) {
    return b.value - a.value;
  });

  sheinScores.sort(function (a, b) {
    return b.value - a.value;
  });

  return { riasec: riasecScores, shein: sheinScores };
}

export default function AppetenceResultats({ ...props }) {
  useEffect(() => {
    props.onChange((draft) => {
      draft.appetence.isResultsComplete = true;
      draft.appetence.scores = scores;
    });
  }, []);

  const appetenceValues = props.values?.appetence?.grilles;
  const scores = getScores(appetenceValues);
  const t = translations.appetence.result;
  const verbes = appetence.verbes;

  return (
    <Container>
      <div>
        <Spacer size={3} />
        <h2>{t.title}</h2>
        <div className="mb-3">{Helpers.textToList(t.intro.text, "div")}</div>
        {t.intro.link_url && t.intro.link_label && (
          <a href={t.intro.link_url} rel="noopener noreferrer" target="_blank">
            {t.intro.link_label}
          </a>
        )}
      </div>
      <Spacer size={3} />
      <Row>
        <Col sm={4}>
          <img src={AssetAppetenceLogo} alt="Logo Appetence" width="300" />
        </Col>
        <Col sm={8}>
          {Object.keys(scores).map((value, index) => (
            <Card className="mb-4" key={index}>
              <CardHeader className="font-weight-bold">
                {t[value].title}
              </CardHeader>
              <CardBody>
                <div>{t[value].text}</div>
                <ul>
                  {scores[value].slice(0, 3).map((item, index) => (
                    <li key={index} className="text-blue">
                      <strong>{verbes[item.id].label}</strong> :{" "}
                      {verbes[item.id].desc}
                    </li>
                  ))}
                  {scores[value].slice(-1).map((item, index) => (
                    <li key={index} className="text-danger">
                      <strong>{verbes[item.id].label}</strong> :{" "}
                      {verbes[item.id].desc}
                    </li>
                  ))}
                </ul>
              </CardBody>
            </Card>
          ))}
        </Col>
      </Row>
      <CourseFooter {...props} />
    </Container>
  );
}
