import React from "react";
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
} from "reactstrap";
import * as PropTypes from "prop-types";
import Spacer from "../layout/Spacer";
import { Link } from "@reach/router";
import translations from "../../gen/translations.json";

export function isWordPairListComplete(pairs, values) {
  const current = Object.keys(values).length;
  const total = pairs.length;
  return current === total;
}

function WordPairSelect({ id, items, value, onChange, readOnly }) {
  return (
    <div
      id={`pair_${id}`}
      className="word-pair-select-span"
      data-selected={value}
    >
      {items.map(({ skill, word }) => {
        const key = `${skill}_${word}`;
        return (
          <div
            data-selected={value === key}
            key={key}
            onClick={() => {
              if (!readOnly) {
                onChange(key);
              }
            }}
          >
            {word}
          </div>
        );
      })}
    </div>
  );
}

export default class WordPairForm extends React.Component {
  state = {
    currentPair: null,
    aKey: "a",
    bKey: "b",
    useInitials: false,
    isHistoryOpen: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.values) !== JSON.stringify(this.props.values)
    ) {
      this.updateState();
    }
  }

  updateState() {
    const completedPairs = Object.keys(this.props.values).length;
    const currentPair = this.getCurrentPair();

    let aKey = "a";
    let bKey = "b";
    let useInitials = false;

    if (currentPair) {
      aKey = currentPair.items[0].word[0].toLowerCase();
      bKey = currentPair.items[1].word[0].toLowerCase();
      useInitials = true;
      if (aKey === bKey) {
        aKey = "a";
        bKey = "b";
        useInitials = false;
      }
    }

    this.setState({ currentPair, aKey, bKey, useInitials }, () => {
      const total = this.props.pairs.length;
      const current = completedPairs;
      this.props.onProgress({ current, total });
    });
  }

  getCurrentPair() {
    const remaining = this.props.pairs.filter(
      ({ id }) => !this.props.values[id]
    );
    if (remaining.length > 0) {
      return remaining[Math.floor(Math.random() * remaining.length)];
    }
    return null;
  }

  componentDidMount() {
    window.addEventListener("keydown", this.keydownHandler);
    this.updateState();
  }

  keydownHandler = (event) => {
    const currentPair = this.state.currentPair;
    if (event.key === this.state.aKey || event.key === this.state.bKey) {
      const item = currentPair.items[event.key === this.state.aKey ? 0 : 1];
      this.props.onChange({
        ...this.props.values,
        [currentPair.id]: `${item.skill}_${item.word}`,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keydown", this.keydownHandler);
  }

  render() {
    const { values, pairs, onChange, nextPath, readOnly } = this.props;
    const currentValue = this.getCurrentValue();
    const { currentPair, useInitials, aKey, bKey } = this.state;

    const t = translations.profilage.couplesmots;
    return (
      <div className="word-pair-form">
        <Container fluid>
          {Object.values(values).length === pairs.length && (
            <div>
              <h3>{t.complete.title}</h3>
              {!readOnly ? <p>{t.complete.text}</p> : ""}
              <p>
                <Link to={nextPath}>{t.complete.next}</Link>.
              </p>
              {!readOnly ? (
                <p>
                  {t.history.reset}{" "}
                  <Link to="" onClick={() => onChange({})}>
                    {t.history.reset_link_label}
                  </Link>
                </p>
              ) : (
                ""
              )}
            </div>
          )}
          {currentPair && (
            <div className="current pair">
              {currentPair.items.map(({ skill, word }, index) => {
                const key = `${skill}_${word}`;
                return (
                  <Button
                    key={key}
                    color={currentValue === key ? "primary" : "light"}
                    onClick={() => {
                      onChange({ ...values, [currentPair.id]: key });
                    }}
                  >
                    {word}
                  </Button>
                );
              })}
            </div>
          )}
          <Spacer size={5} />
          <Card>
            <CardHeader>
              <div className="d-flex">
                <h5 className="flex-fill m-0">{t.history.title}</h5>
                <Button
                  onClick={() =>
                    this.setState({ isHistoryOpen: !this.state.isHistoryOpen })
                  }
                >
                  {this.state.isHistoryOpen ? t.history.hide : t.history.show}
                </Button>
              </div>
            </CardHeader>
            <Collapse isOpen={this.state.isHistoryOpen}>
              <CardBody>
                <div className="selected-pairs">
                  {pairs
                    .filter((pair) => values[pair.id])
                    .map((pair) => {
                      return (
                        <WordPairSelect
                          {...pair}
                          key={pair.id}
                          value={values[String(pair.id)]}
                          onChange={() => {}}
                          readOnly
                        />
                      );
                    })}
                </div>
              </CardBody>
            </Collapse>
          </Card>
        </Container>
      </div>
    );
  }

  getCurrentValue() {
    const { currentPair } = this.state;
    return currentPair && this.props.values[String(currentPair.id)];
  }
}

WordPairForm.propTypes = {
  pairs: PropTypes.array,
  values: PropTypes.object,
  onChange: PropTypes.func,
  onProgress: PropTypes.func,
};
