import React, { Children, useEffect, useState } from "react";
import { Link, Router } from "@reach/router";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export default function Sequence({
  children,
  uri,
  nextPath,
  prevPath,
  onChange,
  values,
  onBreadcrumb = () => {},
  onProgress = () => {},
  nestedBreadcrumbsDisabled = false,
  ...props
}) {
  const childrenArray = Children.toArray(children);
  const clean = (str) => str.replace(/^\/|[/*]$/g, "");
  const [nestedBreadcrumbs, setNestedBreadcrumbs] = useState({});
  const [breadcrumbUpdates, setBreadcrumbUpdates] = useState(0);
  useEffect(() => {
    if (nestedBreadcrumbsDisabled) {
      return;
    }
    onBreadcrumb(
      <Breadcrumb>
        {Children.map(children, (child, index) => {
          const itemPath = `${uri}/${clean(child.props.path)}`;
          const isItemActive = window.location.pathname.indexOf(itemPath) === 0;

          return (
            <BreadcrumbItem>
              {child.props.isLocked ? (
                <span>
                  <span className="mr-1" role="img" aria-label="Locked">🔒</span>
                  {child.props.title || child.props.path}
                </span>
              ) : (
                <Link
                  to={itemPath}
                  getProps={({ isCurrent, isPartiallyCurrent }) => {
                    const isActive = clean(child.props.path)
                      ? isPartiallyCurrent
                      : isCurrent;
                    return {
                      className: isActive ? "active" : "",
                    };
                  }}
                >
                  {child.props.isComplete && <span className="mr-1" role="img" aria-label="Checked">✔</span>}
                  {child.props.title || child.props.path}
                </Link>
              )}
              {nestedBreadcrumbs[itemPath] && isItemActive && (
                <div className="nested-breadcrumb">
                  {nestedBreadcrumbs[itemPath]}
                </div>
              )}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>,
      uri
    );
  }, [nestedBreadcrumbs, window.location.pathname, breadcrumbUpdates]);

  return (
    <div>
      <Router primary={false}>
        {Children.map(children, (child, index) => {
          const prevChild = childrenArray[index - 1];
          const nextChild = childrenArray[index + 1];
          const sequenceProps = {};
          if (prevChild) {
            sequenceProps.prevPath = `${uri}/${clean(prevChild.props.path)}`;
          } else {
            sequenceProps.prevPath = prevPath;
          }
          if (nextChild) {
            sequenceProps.nextPath = `${uri}/${clean(nextChild.props.path)}`;
          } else {
            sequenceProps.nextPath = nextPath;
          }

          return React.cloneElement(child, {
            ...child.props,
            ...sequenceProps,
            onChange: function () {
              setBreadcrumbUpdates(breadcrumbUpdates + 1);
              onChange.apply(null, arguments);
            },
            indexInSequence: index,
            childrenInSequence: childrenArray.length,
            onBreadcrumb: (nestedBreadcrumb, uri) => {
              setNestedBreadcrumbs({
                ...nestedBreadcrumbs,
                [uri + "/"]: nestedBreadcrumb,
              });
            },
            onProgress: function () {
              setBreadcrumbUpdates(breadcrumbUpdates + 1);
              onProgress.apply(null, arguments);
            },
            values,
          });
        })}
      </Router>
    </div>
  );
}
