class Course {
  id;
  type;
  langCode;
  userId;
  created;
  changed;
  accessCodeId;
  values;
  uuid;
  accessCodeValid;
}

Course.createFromApi = function (data) {
  const course = new Course();
  course.id = parseInt(data.id);
  course.type = data.course_type;
  course.accessCodeId = data.ref_access_code;
  course.values = data.values;
  course.uuid = data.uuid;
  course.userId = data.user_id;
  course.created = new Date(data.created);
  course.accessCodeValid = data.valid_access_code;
  return course;
};

export default Course;
