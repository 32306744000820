import React from "react";
import TextAreaForm from "../../form/TextAreaForm";
import translations from "../../../gen/translations.json";
import { Card, CardBody, Container } from "reactstrap";
import Helpers from "../../../classes/Helpers";

export default class PerspectiveSynthese extends React.Component {
  render() {
    return (
      <div>
        <TextAreaForm
          {...this.props}
          translations={translations.perspective.synthese}
          property="perspective.synthese"
          completionProperty="perspective.isSyntheseComplete"
          groups={[
            {
              name: "plan_actions",
              fields: ["mon_but", "mes_objectifs", "actions"],
            },
            { name: "strategie", fields: ["a_vous"] },
          ]}
          grid={false}
        />
        <Container>
          <Card>
            <CardBody>
              {Helpers.textToList(
                translations.perspective.synthese.footer,
                "div"
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}
