import React from "react";

export default function GridForm({
  questions,
  values = { approved: [], rejected: [] },
  readOnly,
  onSubmit,
  onChange,
}) {
  function getQuestions(values) {
    return questions.map((question) => {
      const isRejected = values.rejected.indexOf(question.item) > -1;
      const isApproved = values.approved.indexOf(question.item) > -1;

      const canApprove = values.approved.length < 3;

      const approvedCheckboxStatus =
        !isRejected && isApproved
          ? "checked"
          : canApprove
          ? "unchecked"
          : "disabled";
      const rejectedCheckboxStatus =
        !isApproved && isRejected ? "checked" : "unchecked";

      return {
        ...question,
        approvedStatus: approvedCheckboxStatus,
        rejectedStatus: rejectedCheckboxStatus,
      };
    });
  }

  return (
    <div className="grid-form">
      {getQuestions(values).map((question, index) => {
        return (
          <div key={index} className="grid-form-question">
            <label className="question">{question.question}</label>
            <label className="approve" data-status={question.approvedStatus}>
              {question.approvedStatus === "checked" && <span role="img" aria-label="+1">👍</span>}
              <input
                type="checkbox"
                disabled={question.approvedStatus === "disabled" || readOnly}
                checked={question.approvedStatus === "checked"}
                onChange={(event) =>
                  onChange({
                    approved: event.target.checked
                      ? values.approved.concat([question.item])
                      : values.approved.filter((i) => question.item !== i),
                    rejected: event.target.checked
                      ? values.rejected.filter((i) => question.item !== i)
                      : values.rejected,
                  })
                }
              />
            </label>
            <label className="reject" data-status={question.rejectedStatus}>
              {question.rejectedStatus === "checked" && <span role="img" aria-label="-1">👎</span>}
              <input
                type="checkbox"
                disabled={question.rejectedStatus === "disabled" || readOnly}
                checked={question.rejectedStatus === "checked"}
                onChange={(event) =>
                  onChange({
                    approved: event.target.checked
                      ? values.approved.filter((i) => question.item !== i)
                      : values.approved,
                    rejected: event.target.checked
                      ? [question.item] // Use concat if more than 1 item is needed.
                      : values.rejected.filter((i) => question.item !== i),
                  })
                }
              />
            </label>
          </div>
        );
      })}
    </div>
  );
}
