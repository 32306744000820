import React, { useCallback, useEffect, useState } from "react";
import Profilage from "../parcours/Profilage";
import Perspective from "../parcours/Perspective";
import Appetence from "../parcours/Appetence";

import produce from "immer";
import Spacer from "../layout/Spacer";
import { Badge } from "reactstrap";
import translations from "../../gen/translations.json";
import * as Sentry from "@sentry/react";

const CourseComponents = {
  perspective: Perspective,
  profilage: Profilage,
  appetence: Appetence,
};

const getSaveStatusColor = (status) => {
  switch (status) {
    case "finished":
      return "success";
    case "error":
      return "error";
    case "start":
      return "info";
    case "buffer":
      return "warning";
    case "waiting":
    default:
      return "secondary";
  }
};

export class Course extends React.Component {
  state = {
    course: null,
    values: null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { api, courseId } = this.props;

    if (
      (courseId !== prevProps.courseId && courseId && api.user) ||
      (prevState.course === null &&
        api.user &&
        courseId &&
        !this.state.isLoading)
    ) {
      this.loadCourse();
    }
  }

  patchCourse() {
    this.props.api
      .patchCourse({ ...this.state.course, values: this.state.values })
      .then(() => {});
  }
  loadCourse() {
    this.setState({ isLoading: true }, () => {
      this.props.api.fetchCourse(this.props.courseId).then((course) => {
        Sentry.setExtra("course", course);
        this.setState({
          course,
          values: course.values || {},
          isLoading: false,
        });
      });
    });
  }

  componentDidMount() {
    if (this.props.courseId && this.props.api.user) {
      this.loadCourse();
    }
  }

  render() {
    const { courseId, saveStatus = "", ...props } = this.props;

    const { course } = this.state;
    return (
      <div>
        <Badge className="save-status" color={getSaveStatusColor(saveStatus)}>
          {translations.status.save[saveStatus]}
        </Badge>
        <Spacer />
        {course &&
          CourseComponents[course.type] &&
          React.createElement(CourseComponents[course.type], {
            ...props,
            onChange: (fn) => {
              const newValues = produce(fn)(this.state.values);
              if (
                JSON.stringify(newValues) !== JSON.stringify(this.state.values)
              ) {
                this.setState({ values: newValues }, () => {
                  this.patchCourse();
                });
              }
            },
            readOnly: !course.accessCodeValid,
            values: this.state.values,
          })}
      </div>
    );
  }
}
