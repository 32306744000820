import React, { useState } from "react";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Container,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { navigate, Link } from "@reach/router";
import Spacer from "../layout/Spacer";

import translations from "../../gen/translations.json";

/**
 * Returns the default values structure of the component.
 *
 * @returns {Object}
 */
function GetDefaultValues() {
  return {
    gender: "n",
    lastname: "",
    firstname: "",
    mail: "",
    pass: "",
    pass_confirm: "",
  };
}

/**
 * <SignupForm> component definition.
 */
export default function SignupForm({
  defaultValues,
  onLoginSubmit,
  onSignupSubmit,
  api,
}) {
  if (api.user) {
    navigate("/");
  }
  // Init default values.
  defaultValues = { ...GetDefaultValues(), ...defaultValues };

  const [values, setValues] = useState(defaultValues);
  const [loginValues, setLoginValues] = useState({ email: "", pass: "" });
  // Init translation system.
  const t = translations.signup;

  /**
   * onChange input callback.
   *
   * @param {Object} event
   */
  function onChange(event) {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  function onLoginChange(event) {
    setLoginValues({ ...loginValues, [event.target.name]: event.target.value });
  }
  return (
    <Container>
      <Spacer size={5} />
      <Card>
        <CardHeader>
          <h3 className="pt-3 pb-1">{t.login_title}</h3>
        </CardHeader>
        <CardBody className="p-4">
          <Form
            className="sign-in"
            onSubmit={(event) => {
              event.preventDefault();
              onLoginSubmit(loginValues);
            }}
          >
            <FormGroup row>
              <Label for="loginEmailText" sm={4}>
                {t.email.label}*
              </Label>
              <Col sm={8}>
                <Input
                  type="email"
                  name="email"
                  id="loginEmailText"
                  value={loginValues.email}
                  onChange={onLoginChange}
                  placeholder={t.email.placeholder}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="loginPasswordText" sm={4}>
                {t.password}*
              </Label>
              <Col sm={8}>
                <Input
                  type="password"
                  name="pass"
                  id="loginPasswordText"
                  value={loginValues.pass}
                  onChange={onLoginChange}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={4} />
              <Col sm={2}>
                <Button color="primary">{t.login}</Button>
              </Col>
              <Col sm={3}>
                <Link to="/lost-password">{t.forgotten_password}</Link>
              </Col>
              <Col sm={3} />
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Spacer size={3} />
      <Card>
        <CardHeader>
          <h3 className="pt-3 pb-1">{t.signup_title}</h3>
        </CardHeader>
        <CardBody className="p-4">
          <Form
            className="sign-up"
            onSubmit={(event) => {
              event.preventDefault();
              // TODO : Find a better way.
              if (values.pass !== values.pass_confirm) {
                alert(t.errors.passwords_dont_match);
                return;
              }
              onSignupSubmit(values);
            }}
          >
            <FormGroup row>
              <Label for="genderSelect" sm={4}>
                {t.gender}*
              </Label>
              <Col sm={8}>
                {["n", "f", "m"].map((gender, index) => (
                  <FormGroup check inline key={index}>
                    <Label check>
                      <Input
                        type="radio"
                        name="gender"
                        onChange={onChange}
                        value={gender}
                        checked={values.gender === gender}
                      />{" "}
                      {t[`gender_${gender}`]}
                    </Label>
                  </FormGroup>
                ))}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="lastnameText" sm={4}>
                {t.lastname.label}*
              </Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="lastname"
                  id="lastnameText"
                  value={values.lastname}
                  onChange={onChange}
                  placeholder={t.lastname.placeholder}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="firstnameText" sm={4}>
                {t.firstname.label}*
              </Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="firstname"
                  id="firstnameText"
                  value={values.firstname}
                  onChange={onChange}
                  placeholder={t.firstname.placeholder}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="emailText" sm={4}>
                {t.email.label}*
              </Label>
              <Col sm={8}>
                <Input
                  type="email"
                  name="mail"
                  id="emailText"
                  value={values.email}
                  onChange={onChange}
                  placeholder={t.email.placeholder}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="passwordText" sm={4}>
                {t.password}*
              </Label>
              <Col sm={8}>
                <Input
                  type="password"
                  name="pass"
                  id="passwordText"
                  onChange={onChange}
                  autoComplete="off"
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="passwordConfirmText" sm={4}>
                {t.confirm_password}*
              </Label>
              <Col sm={8}>
                <Input
                  type="password"
                  name="pass_confirm"
                  id="passwordConfirmText"
                  onChange={onChange}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup check>
              <Label check sm={{ size: 8, offset: 4 }}>
                <Input type="checkbox" onChange={onChange} required />{" "}
                {t.consent}
                {t.learn_more.url && t.learn_more.label && (
                  <FormText color="muted">
                    <a href={t.learn_more.url}>{t.learn_more.label}</a>
                  </FormText>
                )}
              </Label>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ size: 8, offset: 4 }}>
                <Button color="primary">{t.register}</Button>
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}
