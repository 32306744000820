import React, { useEffect } from "react";
import CourseFooter from "../../form/CourseFooter";
import SelfEvaluationGrid from "../../form/SelfEvaluationGrid";
import profilage from "../../../gen/profilage.json";
import {
  Card,
  Row,
  Col,
  Container,
  CardTitle,
  CardBody,
  CardText,
  CardHeader,
} from "reactstrap";
import { CircleFill } from "react-bootstrap-icons";
import translations from "../../../gen/translations.json";
import Helpers from "../../../classes/Helpers";
import Spacer from "../../layout/Spacer";

import AssetMahpiLogo from "../../../assets/logo-mahpi.png";
import AssetProfilageLogo from "../../../assets/logo-profilage-blanc.png";

const colors = {
  selfEvaluationValue: {
    disc: "#1e1e1e",
    background: "#848484",
  },
  resultValue: {
    disc: "#04378b",
    background: "#98befa",
  },
  match: {
    background: "#ffc654",
  },
  between: {
    background: "#cdcdcd",
  },
};

function getProfilageScores(profilageNormalizedSkillsValues) {
  const profilageScores = { assets: {}, mahpi: [], soft: {} };
  const getAssetLevel = (score) => {
    const lowBoundary = 4;
    const mediumBoundary = 7;

    return score <= lowBoundary
      ? "low"
      : score <= mediumBoundary
      ? "medium"
      : "high";
  };

  /**
   * Normalize asset level
   */
  Object.entries(profilageNormalizedSkillsValues).forEach(([key, value]) => {
    const level = getAssetLevel(value);
    if (profilageScores.assets[level] === undefined) {
      profilageScores.assets[level] = [];
    }
    profilageScores.assets[level].push(key);
  });

  return profilageScores;
}

export default function TableauAutoEvaluation({ ...props }) {
  useEffect(() => {
    if (props.result) {
      props.onChange((draft) => {
        draft.profilage.isProfilageResultsComplete = true;
      });
    }
  }, []);

  const t = translations.profilage.autoeval;

  const profilageNormalizedSkillsValues =
    props.values.profilage?.normalized ?? {};
  const softSkillsValues = new Map();
  let profilageScores;
  let profilageSkillsGroups = new Map();

  const renderSkillLevelList = (skills) => {
    return skills.map((shortName) => {
      const skill = Helpers.getSkillByShortName(shortName);
      return (
        skill && (
          <li key={shortName}>
            <strong>{skill.name}</strong>
            <div>{skill.description}</div>
          </li>
        )
      );
    });
  };

  if (props.result) {
    profilageScores = getProfilageScores(profilageNormalizedSkillsValues);

    const softSkillsScores = Helpers.getSoftSkillsScores(
      profilageNormalizedSkillsValues
    );

    profilage.auto_eval.forEach((value, index) => {
      const curType = profilageSkillsGroups.get(value.type) || [];
      curType.push(value);
      profilageSkillsGroups.set(value.type, curType);
    });

    softSkillsScores.flat.forEach((value, key) => {
      const values = softSkillsValues.get(value.type) || [];
      values.push(value);
      softSkillsValues.set(value.type, values);
    });
  }

  return (
    <Container>
      <Spacer size={3} />
      {props.result ? (
        <div>
          <h2>{t.result.title}</h2>
          {t.result.link_url && t.result.link_label && (
            <div>
              <a
                href={t.result.link_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t.result.link_label}
              </a>
            </div>
          )}
          <Spacer size={3} />
          <Card className="p-3 mb-5">
            {t.result.intro.text}
            <div className="d-flex">
              <div
                className="p-2 m-2"
                style={{
                  backgroundColor: colors.selfEvaluationValue.background,
                  border: "1px solid",
                  color: colors.selfEvaluationValue.disc,
                  borderColor: colors.selfEvaluationValue.disc,
                }}
              >
                <span className="mr-2">
                  <CircleFill color={colors.selfEvaluationValue.disc} />
                </span>
                {t.result.intro.first}
              </div>
              <div
                className="p-2 m-2"
                style={{
                  color: colors.resultValue.disc,
                  backgroundColor: colors.resultValue.background,
                  border: "1px solid",
                  borderColor: colors.resultValue.disc,
                }}
              >
                <span className="mr-2">
                  <CircleFill color={colors.resultValue.disc} />
                </span>
                {t.result.intro.second}
              </div>
            </div>
          </Card>
        </div>
      ) : (
        <Row className="mb-5">
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{t.consignes.title}</CardTitle>
              </CardHeader>
              <CardBody>
                <CardText>{t.consignes.first}</CardText>
                <CardText>{t.consignes.second}</CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <SelfEvaluationGrid
        colors={colors}
        items={profilage.auto_eval}
        values={props.values}
        readOnly={props.readOnly}
        result={props.result}
        onChange={props.onChange}
      />
      {props.result ? (
        <div>
          <Spacer size={5} />
          <h3>{t.result.scores.title}</h3>
          <Row>
            <Col sm={5}>
              <img src={AssetProfilageLogo} width="300" alt="Logo Profilage" />
            </Col>
            <Col sm={7}>
              <Card>
                <CardBody>
                  {Array.from(profilageSkillsGroups.keys(), (index) => (
                    <div key={index} className="mb-3">
                      <Row key={index}>
                        <Col sm={6} className="text-capitalize">
                          <strong>{index}</strong>
                        </Col>
                      </Row>
                      {profilageSkillsGroups.get(index).map((value, index) => (
                        <Row key={index}>
                          <Col sm={6}>{value.name}</Col>
                          <Col
                            sm={6}
                            className={
                              Helpers.getAssetLevel(
                                profilageNormalizedSkillsValues[
                                  value.shortName
                                ] ?? 0
                              ) === "high"
                                ? "font-weight-bold"
                                : ""
                            }
                          >
                            {profilageNormalizedSkillsValues[value.shortName] ??
                              "?"}
                          </Col>
                        </Row>
                      ))}
                    </div>
                  ))}
                </CardBody>
              </Card>
              <Spacer size={3} />
              <Card>
                <CardHeader>
                  <CardTitle>{t.result.scores.high_medium_title}</CardTitle>
                </CardHeader>
                <CardBody>
                  <u>{t.result.scores.high_label}</u>
                  <ul>
                    {renderSkillLevelList(profilageScores.assets?.high || [])}
                  </ul>
                </CardBody>
              </Card>
              <Spacer size={3} />
              <Card>
                <CardHeader>
                  <CardTitle>{t.result.scores.low_title}</CardTitle>
                </CardHeader>
                <CardBody>
                  <u>{t.result.scores.low_label}</u>
                  <ul>
                    {renderSkillLevelList(profilageScores.assets?.low || [])}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Spacer size={5} />
          <h3>{t.result.mahpi.title}</h3>
          <div>{Helpers.textToList(t.result.mahpi.intro, "div")}</div>
          <Spacer size={3} />
          <Row className="py-3">
            <Col sm={5}>
              <img src={AssetMahpiLogo} width="300" alt="Logo MAHPI" />
            </Col>
            <Col sm={7}>
              <Card className="mb-3">
                <CardHeader>
                  <CardTitle>{t.result.all_by_trend} :</CardTitle>
                </CardHeader>
                <CardBody>
                  {Helpers.getMahpiScore(profilageNormalizedSkillsValues).map(
                    (value, key) => (
                      <Row
                        key={key}
                        className={
                          key === 0 || key === 1
                            ? "font-weight-bold px-3"
                            : "px-3"
                        }
                      >
                        <Col sm={6}>{value.name}</Col>
                        <Col sm={6}>{value.average}</Col>
                      </Row>
                    )
                  )}
                </CardBody>
              </Card>
              {Helpers.getMahpiScore(profilageNormalizedSkillsValues)
                .slice(0, 2)
                .map((value, key) => (
                  <Card key={key} className="mb-3">
                    <CardHeader>
                      <CardTitle>
                        {t.result.trend}{" "}
                        <span className="text-uppercase">{value.name}</span>
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <ul>
                        {value.skills.map((value, index) => (
                          <li key={index}>{value}</li>
                        ))}
                      </ul>
                    </CardBody>
                  </Card>
                ))}
            </Col>
          </Row>
          <Spacer size={3} />
          <h3>{t.result.soft_skills.title}</h3>
          <div>{Helpers.textToList(t.result.soft_skills.intro.first, "p")}</div>
          <Row className="py-4">
            {Array.from(softSkillsValues.keys(), (key) => (
              <Col key={key} sm={4}>
                <Card>
                  <CardHeader>
                    <CardTitle>{key}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {softSkillsValues.get(key).map((value, index) => (
                      <Row key={index}>
                        <Col sm={9}>{value?.name}</Col>
                        <Col
                          className={
                            value?.average >= 70 ? "font-weight-bold" : ""
                          }
                          sm={3}
                        >
                          {value?.average}
                        </Col>
                      </Row>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        ""
      )}
      <CourseFooter {...props} />
    </Container>
  );
}
