import React from "react";
import MonParcours from "./perspective/MonParcours";
import Profilage from "./Profilage";
import PerspectiveIntro from "./perspective/PerspectiveIntro";
import Sequence from "../form/Sequence";
import Appetence from "./Appetence";
import ProfilageSynthese from "./profilage/ProfilageSynthese";
import AppetenceSynthese from "./appetence/AppetenceSynthese";
import translations from "../../gen/translations.json";
import PerspectiveSynthese from "./perspective/PerspectiveSynthese";

export default function Perspective(props) {
  const { values } = props;
  const t = translations.breadcrumb.perspective;
  return (
    <Sequence {...props}>
      <PerspectiveIntro
        path="/"
        title={t.step1}
        isComplete={values.perspective?.isIntroComplete}
      />
      <MonParcours
        path="/parcours"
        title={t.step2}
        isComplete={values.perspective?.isMonParcoursComplete}
        readOnly={props.readOnly}
      />
      <Profilage
        path="/profilage/*"
        title={t.step3}
        isComplete={values.profilage?.isProfilageResultsComplete}
        readOnly={props.readOnly}
      />
      <ProfilageSynthese
        path="/profilage-synthese"
        title={t.step4}
        isLocked={!values.profilage?.isProfilageResultsComplete}
        isComplete={values.profilage?.isSyntheseComplete}
        readOnly={props.readOnly}
      />
      <Appetence
        path="/appetence/*"
        title={t.step5}
        isComplete={values.appetence?.isResultsComplete}
        readOnly={props.readOnly}
      />
      <AppetenceSynthese
        path="/appetence-synthese"
        title={t.step6}
        isLocked={!values.appetence?.isResultsComplete}
        isComplete={values.appetence?.isSyntheseComplete}
        readOnly={props.readOnly}
      />
      <PerspectiveSynthese
        path={"/perspective-synthese"}
        title={t.step7}
        readOnly={props.readOnly}
      />
    </Sequence>
  );
}
