import React from "react";
import { navigate } from "@reach/router";
import { Container, Card, CardBody, CardHeader, Button } from "reactstrap";
import Spacer from "../layout/Spacer";
import Helpers from "../../classes/Helpers";
import TextArea from "./TextArea";
import CourseFooter from "./CourseFooter";

export default function TextAreaForm({
  groups,
  translations = {},
  onProgress,
  onChange,
  property,
  completionProperty,
  nextPath,
  prevPath,
  values = {},
  grid = true,
  readOnly,
}) {
  const allFields = groups.reduce((carry, item) => {
    return carry.concat(item.fields);
  }, []);

  const [prop, subprop] = property.split(".");
  const [completionProp, completionSubprop] = completionProperty.split(".");

  const filledFields = (values) => {
    return allFields.reduce((count, field) => {
      if (values[field]) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onChange((draft) => {
      return draft;
    });
    navigate(nextPath);
  };

  const handleChange = (fieldName) => (event) => {
    const value = event.target.value;
    onChange((draft) => {
      if (!draft[prop]) {
        draft[prop] = {};
      }
      if (!draft[prop][subprop]) {
        draft[prop][subprop] = {};
      }
      draft[prop][subprop][fieldName] = value;
      const filledFieldsCount = filledFields(draft[prop][subprop]);
      onProgress({
        current: filledFieldsCount,
        total: allFields.length,
      });
      draft[completionProp][completionSubprop] =
        filledFieldsCount === allFields.length;
    });
  };

  let formValues = {};
  if (values[prop] && values[prop][subprop]) {
    formValues = values[prop][subprop];
  }
  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Spacer size={3} />
        <h2>{translations.title}</h2>
        {translations.intro && (
          <div>{Helpers.textToList(translations.intro.text, "div")}</div>
        )}
        <Spacer size={3} />
        {groups.map(({ name, fields }) => (
          <Card key={`group-${name}`} className="mb-5">
            {translations.groups && translations.groups[name] && (
              <CardHeader tag="h4">{translations.groups[name]}</CardHeader>
            )}
            <CardBody className="p-0">
              <div className={fields.length > 1 && grid ? "textarea-grid" : ""}>
                {fields.map((name) => (
                  <TextArea
                    key={name}
                    values={formValues}
                    onChange={handleChange}
                    name={name}
                    label={translations.label ? translations.label[name] : name}
                    disabled={readOnly}
                  />
                ))}
              </div>
            </CardBody>
          </Card>
        ))}
        <Card>
          <CardBody>
            <Button>{translations.save_btn || "Valider ma saisie"}</Button>
          </CardBody>
        </Card>
        <CourseFooter
          nextPath={nextPath}
          disabled={
            !filledFields(formValues, allFields.length) === allFields.length
          }
          prevPath={prevPath}
        />
      </form>
    </Container>
  );
}
