import React from "react";
import profilage from "../gen/profilage.json";

class Helpers {}

/**
 * Normalize word pair scores.
 *
 * @param {Object} selectedSkills
 */
Helpers.normalizeWordPairScores = function (selectedSkills) {
  if (profilage.etalonnage === undefined) return false;

  const skillValues = Object.values(selectedSkills).map((key) =>
    key.substring(0, key.indexOf("_"))
  );

  const groupedSkillValues = {};
  const groupedCalibrationData = {};

  const normalizedSkills = {};

  skillValues.forEach((item) => {
    if (groupedSkillValues[item] === undefined) groupedSkillValues[item] = 0;
    groupedSkillValues[item]++;
  });

  profilage.etalonnage.forEach((item) => {
    groupedCalibrationData[item.shortName] = item.values;
  });

  Object.keys(groupedSkillValues).forEach((skillId) => {
    if (
      groupedSkillValues[skillId] === undefined ||
      groupedCalibrationData[skillId] === undefined
    )
      return false;

    const skillValue = groupedSkillValues[skillId];

    const calibrationSkillData = groupedCalibrationData[skillId];
    const calibrationSkillValues = Object.values(calibrationSkillData);

    const normalizedSkillValue = Object.keys(calibrationSkillData).filter(
      (key, index) => {
        const item = calibrationSkillValues[index];

        if (skillValue >= parseInt(item.min)) {
          if (item.max === undefined || skillValue <= parseInt(item.max)) {
            return true;
          }
        }

        return false;
      }
    )[0];

    normalizedSkills[skillId] = parseInt(normalizedSkillValue);
  });

  return normalizedSkills;
};

Helpers.getSkillByShortName = function (shortName) {
  return profilage.auto_eval.filter(
    (skill) => skill.shortName === shortName
  )[0];
};

Helpers.getProSkillByName = function (proSkillName) {
  return profilage.competences.filter(
    (skill) => skill.name === proSkillName
  )[0];
};

/**
 * Calculates the MAHPI scores based on the normalized skills scores.
 *
 * Score is sorted by average, descending.
 *
 * @param {Array} normalizedSkills
 */
Helpers.getMahpiScore = function (normalizedSkills) {
  const mahpiScores = new Map();

  profilage.auto_eval.forEach((item) => {
    const baseScore = normalizedSkills[item.shortName] ?? 0;
    item.mahpi.forEach((mahpiName) => {
      let score = mahpiScores.get(mahpiName);

      if (score === undefined) {
        score = {
          name: mahpiName,
          total: 0,
          count: 0,
          skills: [],
        };
      }

      score.total += baseScore;
      score.count++;
      score.skills.push(item.name);

      mahpiScores.set(mahpiName, score);
    });
  });

  mahpiScores.forEach((value, key) => {
    value.average = Math.round(value.total / value.count);
  });

  return [...mahpiScores.values()].sort((a, b) => b.average - a.average);
};

/**
 * Calculates the Soft Skills scores based on the normalized skills scores.
 *
 * Score is sorted by average, descending.
 *
 * @param {Array} normalizedSkills
 */
Helpers.getSoftSkillsScores = function (normalizedSkills) {
  const scores = {
    flat: new Map(),
    grouped: new Map([
      ["high", new Map()],
      ["low", new Map()],
    ]),
  };

  profilage.auto_eval.forEach((item) => {
    const baseScore = normalizedSkills[item.shortName] ?? 0;

    item.pro.forEach((proName) => {
      let score = scores.flat.get(proName);

      if (score === undefined) {
        const proSkill = Helpers.getProSkillByName(proName);

        score = {
          name: proName,
          type: proSkill.type,
          description: proSkill.description,
          total: 0,
          count: 0,
        };
      }

      score.total += baseScore;
      score.count++;

      scores.flat.set(proName, score);
    });
  });

  scores.flat.forEach((value, key) => {
    value.average = Math.round(value.total / value.count) * 10;
  });

  profilage.competences.forEach((item) => {
    const softSkillScore = scores.flat.get(item.name);

    const level = softSkillScore.average >= 70 ? "high" : "low";

    const groupedSoftSkillsScores =
      scores.grouped.get(level).get(item.type) ?? [];

    groupedSoftSkillsScores.push(softSkillScore);

    scores.grouped.get(level).set(item.type, groupedSoftSkillsScores);
  });

  return scores;
};

Helpers.getAssetLevel = function (score) {
  const lowBoundary = 4;
  const mediumBoundary = 7;

  return score <= lowBoundary
    ? "low"
    : score <= mediumBoundary
    ? "medium"
    : "high";
};

Helpers.textToList = function (text, type = "ul") {
  if (type === "ul") {
    return (
      <ul>
        {text.split("\n").map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  } else {
    return text
      .split("\n")
      .map((item, index) => React.createElement(type, { key: index }, item));
  }
};

export default Helpers;
