import React from "react";
import translations from "../../../gen/translations.json";
import TextAreaForm from "../../form/TextAreaForm";

const groups = [
  {
    name: "caracteristiques1",
    fields: ["appetences"],
  },
  {
    name: "caracteristiques2",
    fields: ["atout_appetences"],
  },
  {
    name: "caracteristiques3",
    fields: ["notes"]
  }
];

export default function AppetenceSynthese(props) {
  return (
    <TextAreaForm
      {...props}
      groups={groups}
      translations={translations.appetence.synthese}
      property="appetence.synthese"
      completionProperty="appetence.isSyntheseComplete"
    />
  );
}
