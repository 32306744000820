import React, { useEffect } from "react";
import appetence from "../../../gen/appetence.json";
import Sequence from "../../form/Sequence";
import GrillePage from "./GrillePage";
import { navigate } from "@reach/router";
import {
  Container,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
} from "reactstrap";
import Spacer from "../../layout/Spacer";
import translations from "../../../gen/translations.json";
import Helpers from "../../../classes/Helpers";

export default function Grilles({ ...props }) {
  const keys = Object.keys(appetence.questions);
  const currentUri = window.location.pathname.replace(/\/$/, "");

  useEffect(() => {
    if (currentUri === props.uri) {
      navigate(`${props.uri}/grille-${keys[0]}`);
    }
  }, []);

  const t = translations.appetence.grilles;

  return (
    <Container>
      <Spacer size={3} />
      <Card>
        <CardHeader>
          <CardTitle>{t.intro.title}</CardTitle>
        </CardHeader>
        <CardBody>
          <p>{t.intro.text}</p>
          {Helpers.textToList(t.intro.list, "ul")}
        </CardBody>
      </Card>
      <Spacer size={3} />
      <Sequence {...props} nestedBreadcrumbsDisabled={true}>
        {keys.map((id) => (
          <GrillePage
            grid={appetence.questions[id]}
            key={id}
            id={id}
            path={`/grille-${id}`}
            readOnly={props.readOnly}
          />
        ))}
      </Sequence>
    </Container>
  );
}
