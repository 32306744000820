import React, { useState } from "react";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

import translations from "../../gen/translations.json";

/**
 * Convert a JS array to an option list to
 * use in an <Input type="select" />.
 *
 * @param {Array} data
 */
function ArrayToOptions(data) {
  return data.map(({ id, label }) => (
    <option value={parseInt(id)} key={id}>
      {label}
    </option>
  ));
}

export default function AccessCodeForm({
  selectListData,
  defaultValues,
  onSubmit,
}) {
  // Init default values.
  defaultValues = { ...{ accessCode: "" }, ...defaultValues };

  const [values, setValues] = useState(defaultValues);
  const t = translations.access_code;

  /**
   * onChange input callback.
   *
   * @param {Object} event
   */
  function onChange(event) {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  return (
    <Card>
      <CardHeader>{t.header_title}</CardHeader>
      <CardBody>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit(values);
          }}
        >
          <FormGroup row>
            <Label for="accessCodeText" sm={4}>
              {t.access_code_label}
            </Label>
            <Col sm={8}>
              <Input
                type="text"
                name="access_code"
                id="accessCodeText"
                value={values.access_code}
                onChange={onChange}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="trainingSelect" sm={4}>
              {t.current_training}*
            </Label>
            <Col sm={8}>
              <Input
                type="select"
                name="training"
                id="trainingSelect"
                value={values.training}
                onChange={onChange}
                required
              >
                <option value="">{t.select}</option>
                {ArrayToOptions(selectListData.trainings)}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="institutionSelect" sm={4}>
              {t.institution}*
            </Label>
            <Col sm={8}>
              <Input
                type="select"
                name="institution"
                id="institutionSelect"
                value={values.institution}
                onChange={onChange}
                required
              >
                <option value="">{t.select}</option>
                {ArrayToOptions(selectListData.institutions)}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="diplomaSelect" sm={4}>
              {t.last_diploma}*
            </Label>
            <Col sm={8}>
              <Input
                type="select"
                name="diploma"
                id="diplomaSelect"
                value={values.diploma}
                onChange={onChange}
                required
              >
                <option value="">{t.select}</option>
                {ArrayToOptions(selectListData.diplomas)}
              </Input>
            </Col>
          </FormGroup>
          <Button color="primary">{t.create_course}</Button>
        </Form>
      </CardBody>
    </Card>
  );
}
