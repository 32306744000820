import React, { useState } from "react";
import { navigate } from "@reach/router";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import translations from "../../gen/translations.json";
import Spacer from "../layout/Spacer";

/**
 * Returns the default values structure of the component.
 *
 * @returns {Object}
 */
function GetDefaultValues() {
  return {
    email: "",
  };
}

export default function LostPasswordForm({ defaultValues, onSubmit, api }) {
  if (api.user) {
    navigate("/home");
  }

  // Init default values.
  defaultValues = { ...GetDefaultValues(), ...defaultValues };
  const t = translations.lostpassword;

  const [values, setValues] = useState(defaultValues);

  function onChange(event) {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  return (
    <Container>
      <Spacer size={5} />
      <Card>
        <CardHeader>
          <h3 className="pt-3 pb-1">{t.title}</h3>
        </CardHeader>
        <CardBody className="p-4">
          <Form
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit(values);
            }}
          >
            <FormGroup row>
              <Label for="emailText" sm={2}>
                {t.email.label}*
              </Label>
              <Col sm={10}>
                <Input
                  type="email"
                  name="email"
                  id="emailText"
                  value={values.email}
                  onChange={onChange}
                  placeholder={t.email.placeholder}
                  required
                />
              </Col>
            </FormGroup>
            <Button color="primary">
              {t.new_password}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}
