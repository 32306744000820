// eslint-disable-next-line no-unused-vars
import axios, { AxiosInstance } from "axios";
import ClientOAuth2, { Token } from "client-oauth2";
import AccessCode from "./classes/AccessCode";
import Course from "./classes/Course";
import async, { reject } from "async";
import debounce from "debounce-promise";
import translations from "./gen/translations.json";
import * as Sentry from "@sentry/react";

class Api {
  /**
   * Client HTTP qui va appeler l'API
   * @type {AxiosInstance}
   */
  client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60 * 1000,
    params: {
      _format: "json",
    },
  });

  /**
   * Client HTTP OAuth2.0 en charge de l'authentification
   * @type {ClientOAuth2}
   */
  auth = new ClientOAuth2({
    clientId: process.env.REACT_APP_API_CLIENT_ID,
    clientSecret: process.env.REACT_APP_API_CLIENT_SECRET,
    accessTokenUri: `${process.env.REACT_APP_API_URL}/oauth/token`,
    authorizationUri: `${process.env.REACT_APP_API_URL}/oauth/authorize`,
    redirectUri: `${process.env.REACT_APP_API_URL}/auth/callback`,
    scopes: ["app"],
  });

  /**
   * Représentation du user courant
   * @type {{}}
   */
  user = null;

  /**
   *
   * @type {ClientOAuth2.Token}
   */
  oauthToken = null;

  isRefreshingToken = false;

  listeners = {};

  refreshTokenHandlers = [];

  /**
   * Au construct de l'API, on ajoute un intercepteur responsable
   * de l'ajout d'un paramètre _format=json à la query string
   *
   * On dépose également les écouteurs qui vont être appelés
   * lorsque l'utilisateur perd ou retrouve sa connectivité
   */
  constructor() {
    this.client.interceptors.request.use(
      function (config) {
        config.params = { _format: "json", ...(config.params || {}) };
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    window.addEventListener("online", this.handleConnection);
    window.addEventListener("offline", this.handleConnection);
    this.onLine = navigator.onLine;
  }

  on(eventName, listener) {
    this.listeners[eventName] = this.listeners[eventName] || [];
    this.listeners[eventName].push(listener);
  }

  dispatch(eventName, eventData) {
    if (this.listeners[eventName]) {
      this.listeners[eventName].forEach((cb) => {
        cb(eventData);
      });
    }
  }

  silentLogin() {
    const accessTokenData = this.getLocalData("accessTokenData", null);
    if (accessTokenData === null) {
      return new Promise((resolve, reject) => {
        reject("No access token found");
      });
    }
    const refreshPromise = new Token(this.auth, accessTokenData).refresh();
    return this.handleAccessToken(refreshPromise);
  }

  /**
   *
   * @param user
   * @returns {Promise<Object>}
   */
  register(user) {
    return this.client
      .post("user/register", {
        name: [user.mail],
        mail: [user.mail],
        firstname: [user.firstname],
        lastname: [user.lastname],
        gender: [user.gender],
        pass: [user.pass],
      })
      .then((response) => {
        return this.login(user.mail, user.pass);
      });
  }

  /**
   * API request to update the current user.
   * @param {Object} values
   */
  updateUser(values) {
    const uri = `api/me`;
    const payload = {
      mail: [values.mail],
      firstname: [values.firstname],
      lastname: [values.lastname],
      gender: [values.gender],
    };

    if (values.pass !== "" && values.pass === values.pass_confirm) {
      payload.pass = values.pass;
      payload.pass_confirm = values.pass_confirm;
    }

    return this.checkToken()
      .then(() => this.client.patch(uri, payload))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  login(username, password) {
    return this.handleAccessToken(this.auth.owner.getToken(username, password));
  }

  logout() {
    // TODO : Trigger Drupal's logout endpoint.
    delete this.oauthToken;
    window.localStorage.clear();
    window.location.reload(false);
  }

  refreshToken() {
    this.isRefreshingToken = true;
    this.dispatch("auth", "refreshToken");
    return this.handleAccessToken(this.oauthToken.refresh());
  }

  checkToken() {
    return new Promise((resolve, reject) => {
      // No token, on ne peut ni s'authentifier, ni redemander un token
      if (!this.oauthToken) {
        return reject("No token present");
      }

      // Le token est présent et est valide, on resolve
      if (!this.oauthToken.expired()) {
        return resolve();
      }

      // On n'est pas déjà en train de
      if (!this.isRefreshingToken) {
        return this.refreshToken()
          .catch((error) => {
            this.refreshTokenHandlers.map(([r, rejectHandler]) => {
              rejectHandler(error);
            });
            Sentry.captureException(error);
            reject("Could not refresh token");
            this.logout();
          })
          .then((token) => {
            this.refreshTokenHandlers.map(([resolveHandler]) => {
              resolveHandler(token);
            });
            return token;
          })
          .then(resolve);
      }

      this.refreshTokenHandlers.push([resolve, reject]);
    });
  }

  lostPassword(username) {
    return this.client
      .post("user/lost-password", {
        mail: username,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        Sentry.captureException(error);
        return error.response;
      });
  }

  resetPassword(username, tempPass, newPass) {
    return this.client
      .post("user/lost-password-reset", {
        name: username,
        temp_pass: tempPass,
        new_pass: newPass,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /**
   * @private
   * @param {Promise<Token>} promise
   * @returns {Promise<Object>}
   */
  handleAccessToken(promise) {
    if (navigator.onLine) {
      return promise
        .then((token) => {
          console.info("received new token");
          this.isRefreshingToken = false;
          this.setLocalData("accessTokenData", token.data);
          this.oauthToken = token;
          this.client.defaults.headers[
            "Authorization"
          ] = `Bearer ${token.accessToken}`;
          this.dispatch("auth", "login");
          return this.client.get("api/me");
        })
        .then((response) => {
          console.info("setting user");
          this.user = response.data;
          Sentry.setUser(this.user);
          this.dispatch("auth", "finished");
          this.playback();
          return this.user;
        })
        .catch((err) => {
          this.dispatch("auth", "error");
          Sentry.captureException(err);
          console.error(err);
          return err;
        });
    }
    return promise.then(() => {
      return {};
    });
  }

  /**
   *
   * @param code
   * @returns {Promise<AccessCode>}
   */
  verifyAccessCode(code) {
    return this.client
      .get("api/access-code", { params: { code } })
      .then((response) => {
        if (response.data[0]) {
          return AccessCode.createFromApiData(response.data[0]);
        }
        throw new Error(translations.global.errors.invalid_access_code);
      });
  }

  /**
   *
   * @param codeId
   * @param courseType
   * @param diploma
   * @param training
   * @param institution
   * @returns {Promise<Number>}
   */
  startCourse(codeId, courseType, diploma, training, institution) {
    return this.checkToken()
      .then(() =>
        this.client.post("api/course", {
          course_type: courseType,
          ref_access_code: codeId,
          ref_diploma: diploma,
          ref_training: training,
          ref_institution: institution,
        })
      )
      .then((response) => {
        return response.data.id;
      });
  }

  /**
   *
   * @returns {Promise<Course[]>}
   */
  fetchCourses() {
    const promise = navigator.onLine
      ? this.checkToken()
          .then(() => this.dispatch("fetch", "start"))
          .then(() => this.client.get("api/courses"))
          .then((response) => {
            const courses = response.data;
            this.setLocalData("api/courses", courses);
            this.dispatch("fetch", "finished");
            return courses;
          })
      : this.getCached("api/courses", {});

    return promise.then((data) => {
      return Object.values(data).map((courseItem) =>
        Course.createFromApi(courseItem)
      );
    });
  }

  /**
   *
   * @param id
   * @returns {Promise<Course>}
   */
  fetchCourse(id) {
    return this.getCached("api/courses", {}).then((courses) => {
      if (navigator.onLine) {
        return this.checkToken()
          .then(() => this.client.get(`api/course/${id}`))
          .then((response) => {
            const course = Course.createFromApi(response.data);
            this.setLocalData("api/courses", {
              ...courses,
              ...{ [id.toString()]: course },
            });
            return course;
          })
          .catch((error) => {
            if (error.response.status === 401) {
              return this.refreshToken()
                .then(() => this.fetchCourse(id))
                .catch(reject);
            }
          });
      }

      return courses[id.toString()];
    });
  }
  /**
   *
   * @param {Course} course
   * @returns {Promise<Course>}
   */
  patchCourse(course) {
    const uri = `api/course/${course.id}`;
    const payload = { values: course.values };
    this.dispatch("save", "waiting");
    return navigator.onLine
      ? this.debouncedPatch(uri, payload)
      : this.buffer(uri, payload, "patch", payload);
  }

  /**
   *
   * @type {function}
   */
  debouncedPatch = debounce(
    (uri, payload) => {
      return this.checkToken()
        .then(() => this.dispatch("save", "start"))
        .then(() => this.client.patch(uri, payload))
        .then((response) => {
          return Course.createFromApi(response.data);
        })
        .then(() => this.dispatch("save", "finished"))
        .catch((error) => {
          if (String(error.response?.status)[0] === "4") {
            return this.logout();
          }
          Sentry.captureMessage("Unable to save course");
          this.dispatch("save", "error");
          return this.buffer(uri, payload, "patch", payload);
        });
    },
    2000,
    { accumulate: false, leading: true }
  );

  // Perte de connectivité : sérialisation et enregistrement des
  // données dans le local storage

  /**
   *
   * @param {String} key
   * @param {any} defaultValue
   * @returns {any}
   */
  getLocalData = (key, defaultValue) => {
    const json = window.localStorage.getItem(key);
    if (json) {
      try {
        return JSON.parse(json);
      } catch (e) {
        Sentry.captureException(e);
        console.error("could not parse JSON in localstorage", json);
      }
    }
    return defaultValue;
  };

  /**
   *
   * @param {String} key
   * @param {any} value
   */
  setLocalData = (key, value) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      Sentry.captureException(e);
      console.error("could not stringify value", value);
    }
  };

  /**
   * Dès que l'on reçoit l'information que le navigateur est à nouveau connecté,
   * on essaye de rejouer les requêtes enregistrées dans le buffer
   */
  handleConnection = () => {
    console.info("navigator.onLine changed", navigator.onLine);
    this.onLine = navigator.onLine;
    if (this.onLine) {
      this.playback();
      this.dispatch("onLine", "connected");
    } else {
      this.dispatch("onLine", "disconnected");
    }
  };

  /**
   *
   * @param {String} uri
   * @param {Object} data
   * @param {String} method
   * @param {any} returnData
   * @returns {Promise<any>}
   */
  buffer(uri, data, method, returnData) {
    return new Promise((resolve) => {
      const bufferedRequests = this.getLocalData(Api.KEY_BUFFERED_REQUESTS, {});
      bufferedRequests[uri] = { data, method };
      this.setLocalData(Api.KEY_BUFFERED_REQUESTS, bufferedRequests);
      this.dispatch("save", "buffer");
      resolve(returnData);
    });
  }

  /**
   * Replay every requests buffered in the local storage
   */
  playback(done = () => {}) {
    const ops = this.getLocalData(Api.KEY_BUFFERED_REQUESTS, {});
    async.forEach(
      Object.keys(ops),
      (url, cb) => {
        const op = ops[url];
        console.info("Replaying request", op);
        this.checkToken()
          .then(() => this.dispatch("save", "replay"))
          .then(() => this.client.request({ ...op, url }))
          .then((result) => {
            console.info("Replayed request", url, op);
          })
          .catch((err) => {
            Sentry.captureException(err);
            console.error("Could not replay request for url", url, err);
            this.dispatch("save", "error");
          })
          .finally(cb);
      },
      () => {
        this.setLocalData(Api.KEY_BUFFERED_REQUESTS, {});
        this.dispatch("save", "finished");
        done();
      }
    );
  }

  /**
   *
   * @param {String} uri
   * @param {any} defaultValue
   * @returns {Promise<any>}
   */
  getCached(uri, defaultValue) {
    return new Promise((resolve) => {
      resolve(this.getLocalData(uri, defaultValue));
    });
  }
}

Api.KEY_BUFFERED_REQUESTS = "buffered_requests";

export default Api;
