import React, { useEffect, useState } from "react";
import { Container, Button, Table } from "reactstrap";
import { Link, navigate } from "@reach/router";
import AccessCodeForm from "../form/AccessCodeForm";
import Spacer from "../layout/Spacer";
import Moment from "moment";
import translations from "../../gen/translations.json";
import taxonomy from "../../gen/taxonomy.json";
import * as Sentry from "@sentry/react";

export function Home({ api, user }) {
  const [courses, setCourses] = useState([]);
  const t = translations.home;

  useEffect(() => {
    if (user) {
      api.fetchCourses().then((c) => setCourses(c));
    }
  }, [user, api]);

  return (
    <Container className="home">
      <Spacer size={3} />
      <AccessCodeForm
        selectListData={taxonomy}
        defaultValues={{ access_code: "" }}
        onSubmit={(values) =>
          api
            .verifyAccessCode(values.access_code)
            .then((accessCode) => {
              return api
                .startCourse(
                  accessCode.id,
                  accessCode.courseType,
                  values.diploma,
                  values.training,
                  values.institution
                )
                .then((courseId) => {
                  return navigate(`/courses/${courseId}`);
                });
            })
            .catch((error) => {
              Sentry.captureException(error);
              alert(error.message);
            })
        }
      />
      <Spacer size={3} />
      {courses.length > 0 && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>{t.type}</th>
              <th>{t.date}</th>
              <th>{t.actions}</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <tr key={course.uuid}>
                <td>{course.type}</td>
                <td>{Moment(course.created).format("DD/MM/YYYY")}</td>
                <td>
                  <Link to={`/courses/${course.id}/`}>
                    {course.accessCodeValid ? (
                      <Button>{t.edit}</Button>
                    ) : (
                      <Button>{t.see}</Button>
                    )}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}
